import UserNotification from 'components/UserNotification/UserNotification'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import {
  selectors as userNotificationSelectors,
  actions as userNotificationActions,
} from 'store/modules/UserNotification'
import {
  actions as headerActions
} from 'store/modules/Header'
import {
  actions as PageActions,
  selectors as pageSelectors
} from 'store/modules/Pagination'
import NotificationHistory from 'components/UserNotification/NotificationHistory'
import mainRoutes from 'constants/mainroutes'


const NotificationContainer = (props) =>{
  const moduleId = 'userNotification'
  const [fetching, setFetching] = useState(false)
  const [selectedNotifcnId, setSelectedNotifcnId] = useState(0)
  const {getUserNotifcationList,
         listData,
         pagination,
         setCurrentPage,
         setPerPageLimit,
         perPage,
         type,
         setBreadcrumb,
         breadcrumbName,
         updateSearchValue,
         userNotifcationOpened,
         updateUserNotifcnOpened,
         ...restProps} = props

  const location = useLocation()
  const { pathname } = location || ''
  useEffect(() =>{
    setCurrentPage(1)
    updateSearchValue('')
    if(breadcrumbName){
      setBreadcrumb(breadcrumbName, location.pathname)
    }
  },[])

  useEffect(() =>{
   const callNotifcnApi =async () =>{
    if(userNotifcationOpened){
      setFetching(true)
      setCurrentPage(1)
      updateSearchValue('')
      await getUserNotifcationList()
      setFetching(false)
      updateUserNotifcnOpened(false)
    }
   }
   callNotifcnApi()
    return () => {
      setCurrentPage(1)
      updateSearchValue('')
      updateUserNotifcnOpened(false)
    }
  },[userNotifcationOpened])

  const handlePagination = async (perPage) => {
    setCurrentPage(perPage)
    setFetching(true)
    await getUserNotifcationList()
    updateUserNotifcnOpened(false)
    setFetching(false)
  }

  const handlePerpageLimit = async (perPage) => {
    setPerPageLimit(perPage)
    setFetching(true)
    await getUserNotifcationList()
    updateUserNotifcnOpened(false)
    setFetching(false)
  }

  const handleNotification = (id) =>{
    setSelectedNotifcnId(id)
  }

  const handleSearch = async(value) =>{
    setCurrentPage(1)
    setFetching(true)
    updateSearchValue(value)
    await getUserNotifcationList()
    setFetching(false)
    updateUserNotifcnOpened(false)
  }

  return(
    pathname === mainRoutes.NOTIFICATION_HISTORY && type === 'notficationDetail' ?
      <NotificationHistory
       listData={listData}
       getUserNotifcationList={getUserNotifcationList}
       perPage= {perPage}
       handlePagination={handlePagination}
       currentPage={pagination?.[moduleId]?.currentPage}
       handlePerpageLimit={handlePerpageLimit}
       totalCount={restProps?.totalCount}
       handleListNotification={handleNotification}
       selectedNotifcnId={selectedNotifcnId}
       setSelectedNotifcnId={setSelectedNotifcnId}
       handleSearch={handleSearch}
       setFetching={setFetching}
       fetching={fetching}
       userNotifcationOpened={userNotifcationOpened}
       {...restProps}
      />
      :
      <UserNotification
        getUserNotifcationList={getUserNotifcationList}
        listData={listData}
        fetching={fetching}
        setFetching={setFetching}
        handleListNotification={handleNotification}
        {...restProps}
      />

    )

}

const mapStateToProps = (state, ownProps) => {
  return {
    listData:userNotificationSelectors.listData(state),
    pagination: pageSelectors.pagination(state)?.toJS(),
    perPage: pageSelectors.perPage(state),
    totalCount: userNotificationSelectors.totalCount(state),
    userNotifcationOpened: userNotificationSelectors.userNotifcationOpened(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getUserNotifcationList:() => userNotificationActions.getUserNotificationList('getUserNotifcationList'),
      setCurrentPage: (page) => PageActions.setCurrentPage(page, 'userNotification'),
      setPerPageLimit: (perPage) => PageActions.setPerPageLimit(perPage, 'userNotification'),
      setBreadcrumb: headerActions.setBreadcrumb,
      updateNotifications: (id) => userNotificationActions.updateUserNotifcation(id),
      updateSearchValue: (value) => userNotificationActions.updateSearchValue(value),
      updateUserNotifcnOpened:userNotificationActions.updateUserNotifcnOpened,
      getUserNotificationCount: userNotificationActions.getUserNotificationCount,
    },
    dispatch
  )
}
NotificationContainer.propTypes = {
  getUserNotifcationList: PropTypes.func,
  listData: PropTypes.object,
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
  setPerPageLimit: PropTypes.func,
  perPage: PropTypes.number,
  type: PropTypes.string,
  setBreadcrumb: PropTypes.func,
  breadcrumbName: PropTypes.string,
  setSeletedNotificationId: PropTypes.func,
  selectedNotifcnId: PropTypes.number,
  updateSearchValue: PropTypes.func,
  userNotifcationOpened: PropTypes.bool,
  updateUserNotifcnOpened: PropTypes.func,
  getUserNotificationCount: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer)
