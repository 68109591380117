import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import './NotificationHistory.scss'
import PropTypes from 'prop-types'
import NotificationList from './NotificationList'
import { Col, Row, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { TablePagination } from '@liquid-design/liquid-design-react'
import NotificationDetails from './NotificationDetails'
import Loader from 'components/Loader'

const NotificationHistory = ({
  listData,
  getUserNotifcationList,
  perPage,
  totalCount,
  handlePagination,
  currentPage,
  handlePerpageLimit,
  handleListNotification,
  selectedNotifcnId,
  setSelectedNotifcnId,
  handleSearch,
  fetching,
  userNotifcationOpened,
  ...restProps
  }) =>{
  const [itemDetails, setItemDetails] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const itemList = listData?.toJS()


  const handleNotification = async (lst) =>{
    const {id, isRead} = lst
    const results = itemList?.find(item => item.id === id)
    setItemDetails(results)
    setShowDetails(true)
    handleListNotification(id)
    if(!isRead){
      restProps?.updateNotifications(id)
      await getUserNotifcationList()
      restProps?.getUserNotificationCount()
    }
  }
  const resetSelection = () =>{
    setItemDetails({})
    setSelectedNotifcnId()
    setShowDetails(false)
  }
  const handleListPagination = (pageNo) => {
    handlePagination(pageNo)
    resetSelection()
  }

  const handleListPerPage = (limitNo) => {
    handlePerpageLimit(limitNo)
    resetSelection()
  }

  const handleSubmit = async (value) => {
    setSearchValue(value)
    setShowDetails(false)
    await handleSearch(value)
  }

  useEffect(() =>{
    if(userNotifcationOpened){
      setSearchValue('')
    }
  },[userNotifcationOpened])

  const seacrhbarClass = `search-bar ${!showDetails ? 'half-container': ''}`


  return (
    <React.Fragment>
      <div className='notifcation-title'>
        <span><FormattedMessage id={`next.NOTIFICATION_HISTORY`} /></span>
      </div>
      <Row className='notfcn-content'>
        <Col xs={24} sm={24} md={!showDetails ? 24 : 10} lg={!showDetails ? 24 : 10}>
              <div>
                <Input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                  className={seacrhbarClass}
                  prefix={<SearchOutlined />}
                  onPressEnter={() => handleSubmit(searchValue)}
                />
              </div>
              <div className='nofcn-lst-div'>

                  {searchValue && itemList?.length === 0
                  ?
                    <div className='empty-notfcn-list'><FormattedMessage id="next.NO_RESULTS" /></div>
                  :
                  <React.Fragment>
                    {fetching && <Loader />}
                    <NotificationList
                      handleNotification = {handleNotification}
                      listData={listData}
                      getUserNotifcationList={getUserNotifcationList}
                      selectedNotifcnId={selectedNotifcnId}
                      showDetails={showDetails}
                      setShowDetails={ setShowDetails }
                    />
                    {itemList?.length > 0 && itemList[0]?.notificationId?.notificationId?.notificationSubject}
                  </React.Fragment>
                  }
              </div>
             {totalCount>0 &&<TablePagination
                itemsPerPageOptions={[10, 25, 50]}
                itemsPerPageAmount={perPage || 10}
                itemsCount={totalCount || 0}
                currentPage={currentPage || 1}
                onChange={handleListPagination}
                onItemsPerPageAmountChange={handleListPerPage}
                className='notfication-pagination'
                isDisplayedBelowTable
              />}
        </Col>
        {showDetails && <Col xs={24} sm={24} md={14} lg={14} className='detail-col'>
         <div className='content-detail'>
            <NotificationDetails
              itemDetails = {itemDetails}
            />
         </div>
        </Col>}
      </Row>
    </React.Fragment>
  )
}

NotificationHistory.propTypes = {
  listData: PropTypes.object,
  getUserNotifcationList: PropTypes.func,
  handlePagination: PropTypes.func,
  handlePerpageLimit: PropTypes.func,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  handleListNotification: PropTypes.func,
  selectedNotifcnId: PropTypes.number,
  setSelectedNotifcnId: PropTypes.func,
  handleSearch: PropTypes.func,
  fetching: PropTypes.bool,
  userNotifcationOpened: PropTypes.bool
}

export default NotificationHistory
